import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TermList from 'components/blog/TermList';
import { Layout, Seo } from 'components/common';
import { Intro } from 'components/sections';

const TopicsPage = ({ pageContext }) => {
  const {
    allWpCategory: { edges },
  } = useStaticQuery(graphql`
    query {
      allWpCategory(
        sort: { fields: count, order: DESC }
        filter: { count: { gte: 1 } }
      ) {
        edges {
          node {
            name
            slug
            description
          }
        }
      }
    }
  `);

  return (
    <Layout context={pageContext}>
      <Seo />
      <Intro title="Topics" subtitle="" button={false} />
      <TermList title="Topics" taxonomy="topics" terms={edges} />
    </Layout>
  );
};

export default TopicsPage;
